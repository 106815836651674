body {
	background-color:#f8f8f8 ;
	letter-spacing: 0.05em;

	&.mobileactive {
		 @media(max-width:$screen-tablet) {
		overflow: hidden;
	}
	}
}

html, body {
	//overflow-x:hidden;
	position:relative;
	height:100%;	
}

.app {
	 display: flex;
  min-height: 100vh;
  flex-direction: column;

  #main {
  	// flex:1;
  }
}

.flex-block {
	display: flex;
	align-items:center;

}

.flexrow {
	display:flex;

	&.flex-equal {
		align-items:stretch;
	}
}
.padblock {
	padding: 20px;
} 

.padblock-lg {
	padding: 20px;
	@media(min-width:$screen-tablet){
	padding: 60px;  
	}  
} 

.heading {
	margin-top:0px;
	margin-bottom:0px;
}  


@media (max-width:$screen-phone) {
	.mb-xs {
		margin-bottom:15px;
	}
	[class^="col-"] {
		width:100%;
		float:none;
	}
	
}

@media (max-width:$screen-tablet) {
	.mb-sm {
		margin-bottom:15px;
	}
	
	 
}

.row-sm {
	margin-left:-7.5px;
	margin-right:-7.5px;
	
	[class*="col-"] {
		padding-left:7.5px;
		padding-right:7.5px;
	}
	
}

.row-xs {
	margin-left:-3px;
	margin-right:-3px;
	
	[class*="col-"] {
		padding-left:3px;
		padding-right:3px;
	}
	
}

   
.row-flush {
	 margin:0px;
	 
	 [class*="col-"] {
		 padding:0px;
	 }
 }

 .fs-15 {
 	font-size: 15px;
 }

  .fs-14 {
 	font-size: 14px;
 }

 .fs-20 {
 	font-size: 20px;
 }
.help-block {
	font-size: 15px;
}
 .mb-zero {
 	margin-bottom: 0px !important;
 }


 .alert {
 	font-size:15px;
 	font-style: italic;
 }

 h1, h2, h3, h4, h5, h6 {
 	margin:0px;
 }

 .font-light {
 	font-weight: 300;
 }


.valign-center {
	position: relative;
	top:50%;
	transform:translateY(-50%);
}

.page-header {
	text-align:center;
	color: white;
	margin:0px;
	padding: 40px 15px;
	border-top: 	1px solid rgba(white, 0.4);
	border-bottom: none;
	position:static;
	z-index:1;

	// &:after {
	// 	content:" ";
	// 	@include position(absolute, 0, 0);
	// 	@include square(100%);
	// 	background-color: rgba(black, 0.05);
	// 	z-index:-1;
	// }

	.page-heading {
		margin-bottom: 	12px;
		+ span{
			font-weight: 300;
		}
	}
}

p {
	@include line-height($font-size-base, 25);
}


.list-group {
	>li {
		margin-bottom: 15px;
		@include line-height($font-size-base, 25);

		.fa {
			margin-top: 4px;
		}
	}
}
