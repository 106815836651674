.flist {
	 
	position: relative;
	padding-bottom: 	40px;
	&:after {
		content:" ";
		border-left:1px dashed $text-color;
		left:30px;
		top:0;
		height: 1%;
		position: absolute;
		width:2px;
		z-index:-1;
		transition:height 2s 1.5s;

		 @include tabletmin {

		 	.list1 & {
		border-right:1px dashed $text-color;
		left:auto;
		right:30px;
		border-left: 	none;
		 		
		 	}
		 }

	}

	&.animated:after {
		height:100%
	}
	&:last-child {
		.list2 & {
			padding-bottom: 	0px;
			&:after {
				display: none;
			}

		}
		 @include tabletmin {
		 
		padding-bottom: 	0px;
		&:after {
			display: none;
		}

	}


	}
	 
	.ficon {
		@include square(64px);
		background-color: 	$text-color;
		color: white;
		text-align: center;
		float:left;
		border-radius:50%;
		line-height: 1;

		 @include tabletmin {

		 	.list1 & {
		 		float:right;
		 	}
		 }

		.fa {
			@extend .valign-center;
			font-size: 30px;
		}
	}
	
	.fcontent {
		margin-left:100px;

		 @include tabletmin {

		 	.list1 & {
		margin-right:100px;
		text-align:right;
		margin-left: 	0px;
		 	 
		 	}
		 }

		p {
			font-weight: 400;
			font-size: 15px;
			 @include tabletmin {
			 	.list1 & {
			 		text-align:right;
			 	}
			 }
		}
	}

	.heading {
		font-size:16px;
		font-weight: 900;
		margin-bottom: 	10px;

		a {
			color:inherit;
		}
	}

}
