.page-content {
	padding:15px 0;
	@media(min-width:$screen-tablet + 1){
	padding: 80px 0;
	}
}  


.bg-image {
	background-size:cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: relative;
	 

	
}


[data-overlaycolor="white"] {
		&:after {
		@include position(absolute, 0, null, null, 0, " ");
		@include square(100%);
		background-color: rgba(white, .2)

		}  
		

	}
[data-overlaycolor="primary"] {
		&:after {
		@include position(absolute, 0, null, null, 0, " ");
		@include square(100%);
		background-color: rgba($brand-primary, .9)

		}
		

	}



