#footer {
	border-top: 1px solid #e1e1e1;
}

.footer-menu-wrap {
	padding-top: 45px;
	padding-bottom: 45px;

}

.footer-header {
	padding-bottom: 10px;
	border-bottom: 2px solid $brand-primary;
	margin-bottom: 20px;
		
	@extend .clearfix;
	h5 {
		font-weight: bold;
		margin: 0px;
		float:left;

	}

	.fa {
		float:right;
		 display: none;
 
	}   
}
   
.footer-menu {
	@extend %listreset;

	li {
		margin-bottom: 	10px;

		a {
			color: 	$text-color;
		}
	}

}

.footer-widget {

	.footer-header {
		@include tabletmax {
			cursor:pointer;

			.fa {
				display: block;
				transition:transform 0.3s;

				@at-root .show-footer#{&}{
					transform:rotate(90deg);

				}
 
			}
		}
	}
	.footer-menu-content {
		@include tabletmax {
		display: none;
	}

	}
}
// @include tabletmax {
// .show-footer {
// 	.footer-header {
// 		.fa {
// 			transform:rotate(90deg);
// 		}
// 	}

// }
// }
.footer-menu-content {
	.social-links {
		@extend %listreset;
		text-align:left;
		margin: 0 auto;

		// @media(min-width:$screen-phone){
		// 	float:left;
		// }

		li {
			display: inline-block;
			margin-right: 25px;
			&:last-child {
				margin-right: 	0px;
			}
			a {
				color: $text-color;
			}
		}
	}

}
.footer-bottom {
	// background-color:rgba($brand-primary, 0.3);
	border-top: 	1px solid #ddd;
	padding: 15px 0;

	
	.copyright-text {
		text-align:center;
		@media(min-width:$screen-phone) {
			float:right;
		}
	}
}


.app-menu {
	 margin:0 -8px;
	 @include clearfix();
	li {
		display: inline-block;
		float:left;
		padding-left: 6px;
		padding-right: 6px;
		a {
			display: inline-block;
		}
	}
}


