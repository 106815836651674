/*=====  
pages:
Home
======*/


/*=================================
=            Jumbotron            =
=================================*/

.jumbotron {
	background: none;
	min-height:550px;
	height: calc(100vh - 80px);
	display: flex;
	align-items:center;
	justify-content:center;
	flex-flow:column nowrap;
	margin-bottom: 	0px;

	.heading {
		color:white;
		text-align:center;
			margin-bottom: 30px;

		font:{
			weight:900;
			size:36px;

		};
		span {
			font-weight: 300;
			display: block;
			margin-bottom: 25px;
		}
	}
}


.scroll-downs {
	display: none;
	@include tabletmin {
		display: block;
	}
  position: absolute;
 
  bottom:30px;
  left:0;
  right:0;
  margin:0 auto;
  
  width :34px;
  height: 55px;
}
.mousey {
  width: 30px;
  // padding: 10px 15px;
  height: 55px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  postion:relative;
}
.scroller {
  width: 4px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
  margin:6px auto;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(6px); opacity: 1; }
  100% { transform: translateY(25px); opacity: 0;}
}
/*=====  End of Jumbotron  ======*/



/*================================
=            features            =
================================*/

	#features {

		@extend %sectionpadding;
		position: relative;
		overflow: hidden;
		@extend %winheight;
		&:after {
			@include position(absolute, 0, 0, null, null, " ", -2);
			@include square(100%) ;
			background-color: $gray-lighter;
			 transform: skewX(-9deg) translateX(45%);

		}
	.featureflex {
		
		display:flex;
		flex-flow:column wrap;
		@include tabletmin {
		width:90%;
		margin:0 auto;
		flex-flow:row wrap;
	}
		 
		.featurelist {
			
			@include tabletmin {
			flex-grow:1;
			flex-basis:30%;

		}
		}
		 .list1 {
		 order:2;
    	 @include tabletmin {
    	 
    	order:1;
    }
	    }
	    .list2 {
	    	 
	   
	    	order:3;
	    
	    }
	    .featurescreen {
	    	order:1;
	    	margin-bottom: 30px;
	    	text-align: center;
		  @include tabletmin {
	 
		order:2;
		text-align: center;
		flex-grow:2;
		margin-bottom: 0px;
	}
		}

	}
}
 

.featurelist {

}
.flist {
	 
	position: relative;
	padding-bottom: 	40px;
	&:after {
		content:" ";
		border-left:1px dashed $text-color;
		left:30px;
		top:0;
		height: 1%;
		position: absolute;
		width:2px;
		z-index:-1;
		transition:height 2s 1.5s;

		 @include tabletmin {

		 	.list1 & {
		border-right:1px dashed $text-color;
		left:auto;
		right:30px;
		border-left: 	none;
		 		
		 	}
		 }

	}

	&.animated:after {
		height:100%
	}
	&:last-child {
		.list2 & {
			padding-bottom: 	0px;
			&:after {
				display: none;
			}

		}
		 @include tabletmin {
		 
		padding-bottom: 	0px;
		&:after {
			display: none;
		}

	}


	}
	 
	.ficon {
		@include square(64px);
		background-color: 	$text-color;
		color: white;
		text-align: center;
		float:left;
		border-radius:50%;
		line-height: 1;

		 @include tabletmin {

		 	.list1 & {
		 		float:right;
		 	}
		 }

		.fa {
			@extend .valign-center;
			font-size: 30px;
		}
	}
	
	.fcontent {
		margin-left:100px;

		 @include tabletmin {

		 	.list1 & {
		margin-right:100px;
		text-align:right;
		margin-left: 	0px;
		 	 
		 	}
		 }

		p {
			font-weight: 400;
			font-size: 15px;
			 @include tabletmin {
			 	.list1 & {
			 		text-align:right;
			 	}
			 }
		}
	}

	.heading {
		font-size:16px;
		font-weight: 900;
		margin-bottom: 	10px;

		a {
			color:inherit;
		}
	}

}

.iphonewrap {
	display: inline-block;
	margin:0 auto;
}



/*=====  End of features  ======*/




/*=============================
=            whyus            =
=============================*/

#whyus {
	position: relative;
		overflow: hidden;
		@extend %winheight;
		&:after {
			@include position(absolute, 0, null, null, 0, " ", -2);
			@include square(100%) ;
			background-color: $gray-lighter;
			 transform: skewX(-9deg) translateX(-63%);

		}
	@extend %sectionpadding;
	.flexrow {
		@include tabletmax {
			display: block;
		}
	}
	.bg-image {
		    position: absolute;
		    right: 0;
		    top: 0;
		    width: 75%;
		    height: 100%;
		    z-index:-3;
		    @include opacity(0.1);

		   
	}	
		 
.flex-block {
		justify-content:center;
		height:100%;
	}	 
}

.whyus {
	position:relative;
	z-index:2;
	.heading {
		font-weight: 900;
		font-size:30px;
		margin-bottom: 20px;
	}
}

/*=====  End of whyus  ======*/



/*====================================
=            how it works            =
====================================*/

.how-it-works {
position:relative;
margin-top: 120px;
z-index:1;
	&:before, &:after {
		@include size(200px, 205px);
		@include position(absolute, 50%, null, null, 0, " ");
		background-color: rgba($brand-primary, 0.2);
		 transform: skewX(-13deg) translateX(-50%) translateY(-50%);
		z-index:-1;
	}

	&:after {
		transform: skewX(-13deg) translateX(-20%) translateY(-25%);

	}



	h3 {
		font-weight:300;
		font-size:30px;

	}

	.video-link {
		border-radius: 50%;
		@include square(90px);
		border:2px solid $text-color;
		display:inline-block;
		font-size: 45px;
		color:$text-color;
		text-align: center;
		line-height: 1;
		transition:all 0.2s;
		vertical-align: middle;
		margin-right: 30px;

		.fa {
			line-height: 1;
			@extend .valign-center;
			transform: translateY(-50%) translateX(5px);

		}

		&:hover {
			text-underline:none;
			border-color:transparent;
			background-color: $brand-primary;
			color:white;

		}


	}




}

/*=====  End of how it works  ======*/




/*=====================================
=            price section            =
=====================================*/

.price-section {
	@extend %listreset;

	li {
		padding: 30px 0;

		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 25px;
			}

	&:nth-of-type(3){
		 
			img {
				margin-top: -10px;
			}
		
		}

	}
}

/*=====  End of price section  ======*/




/*====================================
=            testimonials            =
====================================*/
#testimonials {
	// @extend %winheight;
	position: relative;
	@extend %sectionpadding;
	overflow: hidden;
	&:before {
		@include position(absolute, 0, null, null, 15px, "", 1);
		font-family: "Fontawesome";
		font-size: 350px;
		@include opacity(0.1);
		line-height: 1;
		color: white;

	}


	 .flex-block {
	 	@include tabletmin {
	 	width:65%;
	 }
	 	margin:0 auto;
	 }
}

.testimonial-carausel {
	z-index: 100;

	.client-block {
		padding-bottom: 40px;
		border-bottom: 1px solid white;
	}
	.client-footer {
		padding-top: 20px;
		p {
			font-weight: bold;
			color: white;
		}
	}

	.media-left {
		padding-right: 30px;

		@include tabletmax {
			display:block !important;
			margin:0 auto;
		}
		 

		img {
			 @include square(125px);
			 border-radius:50%;
			 	@include tabletmax {
			 		    margin: 20px auto;
			 	}
		}
		 
	}

	.media-body {
		@include tabletmax {
			display:block !important;
			margin:0 auto;
			width:auto !important;
		}
		p {
			color:white;
			font:{
				size:20px;
				style:italic;
			};
			@include line-height(20, 30);
		}
	}
}

.slick-slider {
	width:100%;
}
/*=====  End of testimonials  ======*/




	