.menutoggle {

 position:relative;
 
 z-index:100; 
 @media(max-width:$screen-tablet) {
 	top:50%;
 	transform:translateY(-50%);
 	 

 	.mobileactive & {
 		 top:30px;
 		 transform:translateY(0%);
 		  position:fixed;
 		  right:15px;
 		    

 	}
 }
   
 
 
 
	background:none;
	border:none;
	outline:none;
	display:none;
	@media(max-width:$screen-tablet) {
			display:block;
			 
		}
		@media(max-width:$screen-phone) {
			
		}
	
	.icon-bar {
		width:20px;
		height:3px;
		background:white;
		margin:4px 0px;
		display:block;
		transform:rotate(0deg);
		transition:all 0.3s;
	}
}
#mobilewrap {
	display:none;
}
	@media(max-width:$screen-tablet) {
#mobilewrap {
	display:block;
	position:fixed;
	background:rgba(185, 60, 73, 0.8);
	width:100%;
	height:100%;
	z-index:9000;
	transform:translateY(100%);
	transition:all 0.5s;
	overflow-y:auto;
	
	.mtable {
		display:table;
		width:100%;
		height:100%;
		padding:20px;
		vertical-align:middle;
		text-align:center;
		
		.mcell {
			display:table-cell;
			vertical-align:middle;
			text-align:center;
			
			.mobilemenu {
				margin:0px;
				padding:0px;
				list-style:none;
				
				>li {
					padding:10px;
					
					>a {
						color:white;
						
						text-transform:uppercase;
						letter-spacing:1.4px;
						padding:10px;
						display:block;
					}
				}
			}
			
			 
		}
	}
}
	

body {
	&.mobileactive {
		#mobilewrap  {
			transform:translateY(0%);
		}
		
		.menutoggle {
			z-index:9999;
			position:fixed;
			
			.icon-bar {
				&:nth-child(2) {
					transform:rotate(45deg) ;
					  transform-origin:50% -50%;

				}
				&:nth-child(3) {
					display:none;
				}
				&:nth-child(4) {
					transform:rotate(-45deg);
					   transform-origin:0 50%;
				}
			}
		}
		
		
	}
}
}