
/*==============================
=            vendor            =
==============================*/
@import "base/variables";
@import "vendor/bootstrap";
@import "vendor/mathfunctions";
@import "vendor/drop-shadow-converter";


 /*=====  End of vendor  ======*/ 

 /*============================
=            base            =
============================*/


@import "base/mixins";
@import "base/global" ;
@import "base/placeholder" ;   

/*=====  End of base  ======*/ 







/*==================================
=            components            =
==================================*/
@import "components/buttons" ;  
@import "components/mobilemenu" ; 
@import "components/carausel" ; 
@import "components/modals" ; 





 /*=====  End of components  ======*/
 

/*==============================
=            layout            =
==============================*/

@import "layout/header";
@import "layout/footer";
@import "layout/form";

/*=====  End of layout  ======*/

/*=============================
=            pages            =
=============================*/
@import "pages/cardjumbo";
@import "pages/page";
@import "pages/pricing";
@import "pages/home";
@import "pages/features";
@import "pages/testimonials";




 



/*=====  End of pages  ======*/

 
 