/*=====  
pages:
signup
login
punch
payment

======*/


.card-jumbo {
	display:flex;
	background-color: white;
	position: relative;
	@media(max-width:$screen-tablet){
		flex-direction:column;
		 
	}

	@media screen and (max-width:$screen-phone){
		margin-bottom: 	50px;
	}
	&:after {
		@include position(absolute, null, 0, 2px, 0, " ", -1);
		width:80%;
		height: 50%;
		margin:0 auto;
		background-color: black;
		box-shadow: drop-shadow-converter(#000, $angle: 90, $distance: 26px, $spread: 14, $size: 95px, $opacity: 30%);
		// @include photoshop-drop-shadow ($angle: 90, $distance: 26, $spread: 14, $size: 95, $color: fade-in(black, 0.3))
	}


	.flex-6 {
		@media(min-width:$screen-tablet + 1){
		flex-basis:50%;
		}

		.padblock-lg {
			padding-top:35px;
			padding-bottom: 35px;
		}

	}

	.form-header {

		.heading {
			font-size: 30px;
			margin-bottom:10px;
		}

	}
} 

.radio-custom {
	margin-bottom: 	20px;
	ul {
		@extend %listreset;
		@include clearfix();
		margin-left: 	-10px;
		margin-right: 	-10px;
		@media(max-width:$screen-phone){
			margin-left: 	-2px;
			margin-right: 	-2px;
		}

		li {
			display: inline-block;
			width:calc(100%/4);
			float:left;
			padding:0 10px;

			
			@media(max-width:$screen-phone){
				padding:0 2px;
			}

			input {
				display: none;

				&:checked {
					+ label {
						background-color:$brand-primary;
						color:white;
					}
				}

			}

			label {
				border:1px solid #ccc;
				display: flex;
				align-items:center;
				justify-content:center;
				padding: 20px 25px;
				height:86px;
				box-sizing:border-box;
				margin:0px;
				font-weight: normal;
				text-align:center;
				cursor:pointer;

				@media (max-width:$screen-phone){
				font-size:14px;
			}

			}
		}
	}
} 
.overlay-content {
	display: block !important;
	padding: 20px;

}
.overlay-content-block {
	color:white;
	position:relative;
	z-index: 1;
	text-align: center;
		height: 100%;
	

	.overlay-center-block {
		
	position: relative;

	span {
		font-size: 24px;
		font-weight: 300;
	 



	}
	@media(min-width:$screen-tablet + 1){
		top:50%;
		transform:translateY(-50%);
	}

	}
	


}

.clock-counter {
	font-size:60px;
	@include tabletmax{
	font-size:30px;

	}

	 

} 

.day-counter, .day-current {
	@media(min-width:$screen-tablet + 1){
	position: absolute;
	bottom:0px;
	font-weight: 300;
	font-size: 24px;
}
}

.day-counter {
	left:0px;
	@include tabletmax{
		&:after{
			content:",";
			display: inline-block;
		}
	}
}

.day-current {
	right:0px;
}

.price-large {
	font-size: 60px;

}



