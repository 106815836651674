.testimonial-block {
	.media-left {
		    padding-right: 30px;

		    img {
		    	@include square(100px);
		    	border-radius:50%;
		    }
	}

	.media {
		padding-bottom: 30px;
		border-bottom:1px solid #ccc;

		&:last-child {
			border-bottom: none;
		}
	}

	.media-body {
		padding-top: 20px;
	}
}