.owl-dots, .slick-dots {
	margin:0 auto;
	text-align:center;

	.owl-dot, >li {
		background-color: rgba(white, 0.3);
		@include square(14px);
		display: inline-block;
		border-radius: 50%;
		margin:5px;
		transition:all 0.2s;

		button {
			background: none;
		    outline: none;
		    border: none;
		    text-indent: -9999px;
		}

		&.active, &.slick-active {
		background-color: rgba(white, 1);
		transform:scale(1.2);


		}
	}
}