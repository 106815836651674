.btn { 
	border-radius: 2px;
	box-shadow: none;
	border-color:transparent;
	line-height: 1;
	height: 52px;
	padding:15px 30px;
	letter-spacing: inherit;
	transition:all 0.2s;

	>.fa {
		margin-right: 	6px;
	}

    
	&-fluid {
		width:100%;
	}

	&-paypal {
	background-color: #002f86;
	color: white; 

		&:hover, &:focus {
			color:white;
		}  
	} 
	
	&-outline {
		border:1px solid $text-color;
			color:$text-color;

		&:hover, &:active, &:focus {
			color: white;
			background-color: darken($brand-primary, 5%);
			border-color:darken($brand-primary, 8%);
		}

	}

	&-outline-inverse {
		border:1px solid white;
		color:white;

		&:hover, &:active, &:focus {
			color: white;
			background-color: darken($brand-primary, 5%);
			 
		}
	}

}

.button-inline {
	margin:-6px;
	>.btn {
		margin:6px
	}
}


