.form-control {
	box-shadow:none;
}
.form-group {
	position:relative;
	margin-bottom: 20px;

	&.error {
		input:not([type="reset"]):not([type="submit"]) {
			border-color:red;   
		}

	}


}

.input-icon-right {
	transform:translateY(-50%);
	@include position(absolute, 50%, 12px);
	pointer-events:none;
 

		
}
.input-label {
	position:absolute;
	top:50%;
	transform:translate(12px, -50%);
	font-size:16px;
	font-weight: normal;
	color: #9c9c9c;
	z-index:10;   
	pointer-events:none;
	padding:4px;
	@include opacity(0);
	background-color: transparent;
	transition:all 0.3s ease-in-out;

}

.show-label .input-label,{
	transform:translate(12px, -15px);
	font-size:14px;
	color:$text-color;
	background-color: white;
	top:0px;
	@include opacity(1);

}

select {
	appearance:none;
}



input[type="radio"], input[type="checkbox"] {
	margin-top:5px;
 }

 .input-domain {
 	@media screen and (max-width:479px) {
 		display: block !important; 

 		.input-group-addon {
 			border-left:1px solid #ccc;

 		}
 	}
 }   


