%listreset {
    margin: 0;
    padding: 0;
    list-style: none;
}


%sectionpadding {
	padding:85px 15px;
} 


%winheight {
	min-height: 650px;
	min-height: 100vh;
}    