// based on the work of http://www.melanieceraso.com/psd-to-css3/
@function drop-shadow-converter($color: #000, $opacity: 100, $angle: 0, $distance: 0, $spread: 0, $size:0, $is-text-shadow: false) {

  // calculate angle ∠
  $__ang: (180-$angle) * 3.14 / 180; //convert to radians

  // vertical shadow: offset-y = Sin(∠) * Hypotenuse
  $__offset-y: round(sin($__ang) * $distance);

  // horizontal shadow: offset-x = Cos(∠) * Hypotenuse
  $__offset-x: round(cos($__ang) * $distance);

  // spread-radius equals the Photoshop Size multiplied by the Photoshop Spread percentage
  $__spread-rad: $size * $spread/100;

  // blur-radius is equal to the Photoshop Size minus the <spread-radius>
  $__blur-rad: $size - $__spread-rad;

  // variable for scope context
  @if ($is-text-shadow) {
    @return $__offset-x $__offset-y $__blur-rad $__spread-rad rgba(red($color), green($color), blue($color), $opacity/100);
  } @else {
    @return $__offset-x $__offset-y $__blur-rad rgba(red($color), green($color), blue($color), $opacity/100);
  }
}
