.modal {

	.modal-content {
		border-radius:2px;
	}
	iframe {
		border:none;
	}

	.modal-header {
		padding: 15px 30px;
	}
	.modal-body {
		padding:30px;
	}
}


.modal-fluid {
	.modal-body {
		padding:0px;
	}

	button.close {
		position: absolute;
		left:100%;
		margin-left: 15px;
		opacity:1;
		color: white;
	}
}