.header-top {
	 @include gradient-vertical(#ffffff, #cfdeed, 0%, 100%);
}

.header-nav-top {
	text-align: right;
	padding:12px;
	ul {
		 @extend %listreset;
		font-size:0;
    
		li {
			display: inline-block;

			a {
				display: block;
				font-size:14px;
				padding:10px 16px;
				line-height: 1;
				color:$text-color;
				border:1px solid transparent;

				&:hover {
					text-decoration: none;
				}

				&.active {
					border-color:$text-color;
				}
			}
		}
	}
}


.header-main {
	background-image:url('../images/header-bg.jpg');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size:cover;
	position: relative;
	z-index: 100;

	&:after {
		@include square(100%);
		position: absolute;
		content:" ";
		top:0;
		left:0;
		@include opacity(0.9);
		background-color: #3f8edf;
		z-index: -1;

	}

	.header-navigation {
		transition:all 0.5s;

	.flex-block {
		justify-content:space-between;
		height:80px;
	}
}
}


.logo {
	display: inline-block;
	padding:0px;
	margin:0px;

	img {
		@include img-responsive();
		@media(max-width:$screen-phone){
			width:80%;
		}
	}
}

.main-navigation {
	height:100%;

ul {
	@media screen and (max-width: $screen-tablet){
		position:fixed;
		left:0;
		top:100%;
		@include square(100%);
		background-color: 	rgba($brand-primary, 0.8);
		transition:top 0.5s;
		margin:0px !important;
		display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;


		.mobileactive & {
			display: block;
			top:0;
		}

		li {
			margin: 10px 0;
			a {
				color:white;
				font-size:20px;
			}
		}
	}
 }
	.navbar-toggle {
		display: block;
		@media screen and (min-width:$screen-tablet){
			display: none;
		}

	}

	ul {
		 @extend %listreset;
		@include clearfix();
		height: inherit;
		margin-left:-5px;
		margin-right:-5px;
		@media screen and (max-width:$screen-tablet){
			display: none;
		
		}
		@media screen and (min-width:$screen-tablet){

				
		&:hover {
			li {
				a {
					@include opacity(0.5);
					filter:blur(1px);
					transition:filter 0.5s;
				}

				&:hover {
					a {
						@include opacity(1);
						text-shadow:0px 0px 5.64px rgba(255, 255, 255, 1);
						filter:blur(0);
						transition:filter 0.5s;

					}
				}
			}
		}

		li {
			float:left;
			height: inherit;
			margin:0 5px;
				display: flex;
				align-items:center;

			a {
				display: inline-block;
				 
			
				color:white;
				padding:10px;
				height:auto;
				//text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
				transition:text-shadow 0.5s;

				&:hover, &:active, &.active {
					text-shadow:0px 0px 5.64px rgba(255, 255, 255, 1);
					text-decoration: none;
					color:white;
				}


			}

		}
		}
	}
}


.header-navigation {
	transition:all 0.5s;
	// position: fixed;
	// width:100%;

	&.sticky {
		background: rgba($brand-primary, 1);
		@include position(fixed, 0, 0 , null, 0, null, 200);
		width:100%;

	}

	&.nav-down {
		// position: fixed;
		top:0;
	transition:all 0.5s;
		
	}

	&.nav-up {
		// position: relative;
		top:-100%;
	transition:all 0.5s;

	}

	 

}