/*=====  
pages:
pricing
======*/


.priceblock {
	$p:&;
	box-shadow: drop-shadow-converter(#000, $angle: 120, $distance: 0px, $spread: 0, $size: 16px, $opacity: 13%);
	transition:box-shadow 0.3s linear;
	border:1px solid #d4d4d4;
	margin-bottom: 20px;
	&__header {
		$i:&;
		padding: 20px;
		background-color: white;
		position: relative;
		padding-bottom: 10px;


	}

	&__plan {
		position: absolute;
		top:20px;
		right:20px;
		font:{
			size:24px;
			weight: 300;
			style: italic;

		}
		
	}

	&__container {
		margin-bottom: 25px;
		.price {
			font:{
				size:36px;
				weight:bold;

			};
			color:black;
			display: block;

		}

	}

	&__content {
		background-color: #f8f8f8;
		border-top: 1px solid #d4d4d4;
		border-bottom: 1px solid #d4d4d4;
		padding: 20px;


	}

	&__features {
		@extend %listreset;
		text-align: center;

		>li {
			line-height: 1;
			padding: 10px 0;
			color: black;
		}
	}

	&__footer {
		padding:20px;
		text-align:center;
	}


	&--recommended {
		$r:&;
		border-color:$brand-primary;
	box-shadow: drop-shadow-converter(#000, $angle: 120, $distance: 15px, $spread: 14, $size: 38px, $opacity: 30%);
	position: relative;
	@include desktopmin {
		transform:translateY(-20px);

	}
	&:before {
		content:attr(data-type);
			@include desktopmin {
				@include position(absolute, null, 0, 100%, 0);
				padding: 0px;
			}
		text-align:center;
		display: block;
		padding: 15px;
		font:{
			weight:300;
			style:italic;
			size:24px;
		};
		margin-bottom: 8px;

		

	}



		#{$p}__header  {
			background-color: $brand-primary;
			color: white;

			.price {
				color:white;
			}

			.btn-primary {
				border-color:white;
				// background-color: transparent;
			}
			.btn-link {
				color: white;
			}
		}

		#{$p}__content {
			border-color:$brand-primary;
		}

	}

	&:hover {
	box-shadow: drop-shadow-converter(#000, $angle: 120, $distance: 15px, $spread: 14, $size: 38px, $opacity: 30%);


	}
}
