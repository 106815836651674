//margin-bottom
@for $i from 1 through 12 {
	.mb-#{$i}x {
		margin-bottom:5px *$i !important;
	}
}

//margin-top
@for $i from 1 through 12 {
	.mt-#{$i}x {
		margin-top:5px *$i !important;
	}
}

//padding top
@for $i from 1 through 12 {
	.pt-#{$i}x {
		padding-top:5px *$i !important;
	}
}
//padding bottom
@for $i from 1 through 12 {
	.pb-#{$i}x {
		padding-bottom:5px *$i !important;
	}
}

@media screen and (max-width:$screen-phone) {
[class*="col-tiny"] {
	float:left !important;
		padding-left:15px;
		padding-right:15px;

}
@for $i from 1 through 12 {
	.col-tiny-#{$i} {
		width:100%/12 * $i !important;

	}
}
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex-basis:100%/12 * $i !important;

  }
}




@mixin position($position, $top: null, $right: null, $bottom: null, $left: null, $content:null, $z-index: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  content: $content;
  z-index: $z-index;
}


$phoneonly: "(min-width:#{$screen-phone})" and "(max-width:#{$screen-tablet} - 1)";
$tabletonly: "(min-width:#{$screen-tablet})" and "(max-width:#{$screen-desktop} - 1)";
$phonemin: "(min-width:#{$screen-phone})";
$phonemax: "(max-width:#{$screen-phone} -1)";
$phoneonly: "(min-width:#{$screen-phone})" and "(max-width:#{$screen-tablet} - 1)";
$tabletmin:"(min-width:#{$screen-tablet})";
$tabletmax:"(max-width:#{$screen-tablet} - 1)";
$desktopmax:"(max-width:#{$screen-desktop} - 1)";
$desktopmin:"(min-width:#{$screen-desktop})";


 

@mixin tabletmin {
  @media #{$tabletmin} {
    @content;
  }
}

@mixin tabletmax {
  @media #{$tabletmax} {
    @content;
  }
}

@mixin tabletonly {
  @media #{$tabletonly} {
    @content;
  }
}

@mixin desktopmax {
  @media #{$desktopmax} {
    @content;
  }
}

@mixin desktopmin {
  @media #{$desktopmin} {
    @content;
  }
}

@mixin phonemin {
  @media #{$phonemin} {
    @content;
  }
}
@mixin phonemax {
  @media #{$phonemax} {
    @content;
  }
}

@mixin phoneonly {
  @media #{$phoneonly} {
    @content;  
  }
}     




@mixin line-height($fontsize, $lineheight ){

    line-height: ($lineheight) + px;
    line-height: ($lineheight/10) + rem;

    margin-top: (($lineheight - $fontsize)/2 * -1) ;
    margin-top: ((($lineheight - $fontsize)/2 * -1) / 10 );
}

/* Letter spacing is simpler.. stolen from somewhere */
@mixin letter-spacing($letterspacing) {
    letter-spacing: $letterspacing/1000;
}